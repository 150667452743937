import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
const Login = React.lazy(() => import("../auth/UserLogin/Login"));

const NoAuthRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default NoAuthRoutes;
