import AuthRoutes from "./routes/AuthRoutes";
import NoAuthRoutes from "./routes/NoAuthRoutes";
import { useAuth } from "./shared/hooks/auth-hook";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./shared/functions/ScrollToTop";
import { Center, Spinner, Stack } from "@chakra-ui/react";
import { Suspense, useState } from "react";
import Footer from "./shared/components/Footer";
import MainNavigation from "./shared/components/MainNavigation";

function App() {
  let routes;
  const [click, setClick] = useState(false);
  const { id, token, login, type, checked, logout } = useAuth();

  if (token && type === "user") {
    routes = <AuthRoutes />;
  } else if (checked) {
    routes = <NoAuthRoutes />;
  }

  return (
    <Stack
      onClick={(e) => {
        if (click) {
          setClick(false);
        }
      }}
    >
      <ScrollToTop />
      {/* {((token && type !== "admin") || !token) && <MainNavigation />} */}
      <MainNavigation setClick={setClick} click={click} />
      <Stack spacing={"30px"}>
        <main>
          {" "}
          <Suspense
            fallback={
              <Center my="100px">
                <Spinner w="50px" h="50px" />
              </Center>
            }
          >
            {checked ? (
              routes
            ) : (
              <Center my="100px">
                <Spinner w="50px" h="50px" />
              </Center>
            )}{" "}
          </Suspense>
        </main>
        <Footer />
      </Stack>
    </Stack>
  );
}

export default App;
