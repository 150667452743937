import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Divider,
  HStack,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { featuresData, filterData, pricesData } from "../Data";
import pickFilter from "../../../assets/pickFilterMobile.svg";
import FilteredProducts from "./FilteredProducts";
import SearchSection from "./SearchSection";
import PriceRange from "./PriceRange";
import BlogSection from "./BlogSection";

const FilterSection = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Stack
      px={{
        base: "0.75%",
        sm: "2%",
        md: "1%",
        lg: "2%",
        xl: "4%",
        "2xl": "6%",
      }}
      py={10}
      direction={{ base: "column", md: "row" }}
      divider={<StackDivider borderColor="gray.200" />}
      style={{ overflow: "hidden" }}
      justify="center"
    >
      <Container
        w={{ base: "100%", md: "48%", lg: "28%", xl: "20%", "2xl": "20%" }}
        h="100%"
        mb="30px"
        display={{ base: "none", md: "flex" }}
      >
        <Stack spacing={8} StackDivider>
          <SearchSection title="" filterData={filterData} />
          <Divider orientation="horizontal" />
          {/* <PriceRange /> */}
          {/* <SearchSection filterData={pricesData} /> */}
          <SearchSection filterData={featuresData} />
        </Stack>
      </Container>
      <Container
        w={{ base: "100%", lg: "17.5%", xl: "20%", "2xl": "20%" }}
        h="100%"
        mb="30px"
        display={{ base: "flex", md: "none" }}
      >
        <Stack w="100%" direction={"row"} justify={"space-between"}>
          <Text>Categories</Text>
          <Img onClick={onOpen} cursor="pointer" src={pickFilter} />
        </Stack>
        <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />{" "}
            <ModalBody py="40px">
              <Stack>
                <SearchSection filterData={filterData} />
                <PriceRange />
              </Stack>
              <HStack pt="20px">
                <Box flexGrow={1} />
                <Button
                  alignSelf={"end"}
                  onClick={onClose}
                  bg={"#2FC61F"}
                  color={"white"}
                  _hover={{
                    bg: "#FFF",
                    color: "#2FC61F",
                    border: "1px solid #2FC61F",
                  }}
                >
                  {" "}
                  Search{" "}
                </Button>
              </HStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Container>
      <Box
        w={{ base: "100%", md: "48%", lg: "72%", xl: "80%", "2xl": "85%" }}
        h="100%"
      >
        <Stack spacing={"30px"}>
          <FilteredProducts />
          <BlogSection />
        </Stack>
      </Box>
    </Stack>
  );
};

export default FilterSection;
