import { Heading, Img, Stack, Text } from "@chakra-ui/react";
import React from "react";
import homeTopPic from "../../../assets/products/pc/XCM_1091025_Manual_750x375_1091025_pcgg_post_laptop_cg_750x375_jpg_PC147_Post_PCGG_creative.jpg";

const TopDescription = () => {
  return (
    <Stack>
      <Stack direction={{ base: "column", md: "row" }}>
        <Stack
          justify={"center"}
          align={"center"}
          w={{ base: "100%", md: "40%" }}
          px="5%"
        >
          <Heading>Computer</Heading>
          <Text textAlign={"justify"}>
            {" "}
            It might be work, but it doesn't have to feel like it. All it takes
            is egonomic yet comfortable and stylish home office furniture to
            keep
          </Text>
        </Stack>
        <Img
          objectFit={"cover"}
          maxH="310px"
          w={{ base: "100%", md: "60%" }}
          src={homeTopPic}
        />
      </Stack>
      <div />
    </Stack>
  );
};

export default TopDescription;
