import {
  Box,
  Button,
  Heading,
  HStack,
  Img,
  Stack,
  Text,
  Wrap,
} from "@chakra-ui/react";
import React from "react";
import { MdOutlineAccessTime } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useLang } from "../../../shared/hooks/lang-hook";
import { blogsData } from "../Data";

const BlogSection = () => {
  const { changeLang, isOro, isAmh } = useLang();

  const navigate = useNavigate();
  return (
    <Stack py="15px" justify={"center"} spacing="20px">
      <Heading alignSelf={"center"}>
        {" "}
        {isOro ? "Barreeffama" : isAmh ? "ዜና" : "Blogs"}
      </Heading>
      <Wrap justify={"center"} spacing="40px" align="center">
        {blogsData.map((blog) => (
          <Box w="280px" borderRadius={"15px"} border="1px solid #4962f2">
            <Stack spacing={"15px"} p={2}>
              <Img src={blog?.img} />
              <HStack>
                <Box flexGrow={"1"} />
                <Text
                  borderRadius="10px"
                  px={4}
                  py={1}
                  bgColor={"#cbd1c4"}
                  color="#4962f2"
                >
                  Phones
                </Text>
              </HStack>
              <Text>{blog?.title}</Text>
              <HStack>
                <MdOutlineAccessTime color="#4962f2" />
                <Text>{blog?.time}</Text>
              </HStack>
              <Button
                bg={"#4962F2"}
                _hover={{
                  bg: "#FFF",
                  color: "#4962F2",
                  border: "1px solid #4962F2",
                }}
                borderRadius={"20px"}
                bgColor={"#4962F2"}
                color="#FFF"
                h={"45px"}
                onClick={() => navigate("/blogs/1")}
              >
                Read
              </Button>
            </Stack>
          </Box>
        ))}
      </Wrap>
    </Stack>
  );
};

export default BlogSection;
