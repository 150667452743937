import { Stack } from "@chakra-ui/react";
import React from "react";
import TopSelectionTab from "../../shared/components/TopSelectionTab";
import FilterSection from "./components/FilterSection";
import TopDescription from "./components/TopDescription";

const ComputerPage = () => {
  return (
    <Stack>
      <TopSelectionTab />
      {/* Change computer or mobile below */}
      <TopDescription />
      <FilterSection />
    </Stack>
  );
};

export default ComputerPage;
