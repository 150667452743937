import { Button } from "@chakra-ui/button";
import { Img } from "@chakra-ui/image";
import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    *{
        box-sizing:border-box;
        margin:0;
        padding:0;
        font-family: 'Helvetica','Source Sans Pro', 'Times';
        ${"" /* background-color: '#F8F8F8'; */}
    }
`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  padding: ${({ pt }) => (pt ? `${pt} 40px 0px 50px` : "0px 50px")};
  @media screen and (max-width: 1280px) {
    padding: ${({ pt }) => (pt ? `${pt} 20px 0px 20px` : "0px 25px")};
  }
  @media screen and (max-width: 1250px) {
    padding: ${({ pt }) => (pt ? `${pt} 50px 0px 50px` : "0px 25px")};
  }
  @media screen and (max-width: 992px) {
    padding-right: 30px;
    padding-left: 30px;
    padding-inline: 30px;
  }
  @media screen and (max-width: 768px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  @media screen and (max-width: 480px) {
    padding-right: 8px;
    padding-left: 8px;
  }
  @media screen and (max-width: 350px) {
    padding-right: 0px;
    padding-left: 0px;
  }
`;

export const AdminContainer = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
`;

export const StyledButton = styled.button`
  border-radius: 10px;
  flex-direction: "row";
  display: "flex";
  background: ${({ primary, logout }) =>
    primary ? "#9561FB" : logout ? "#212652" : "#fff"};
  white-space: nowrap;
  padding: ${({ big, small }) =>
    big ? "12px 64px" : small ? "0px" : "10px 20px"};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    transition: all 0.3s ease-out;
    color: ${({ primary }) => (primary ? "#9561FB" : "#fff")};
    border: ${({ primary }) => (primary ? "1px solid #9561FB" : "none")};
    background: ${({ primary }) => (primary ? "#fff" : "#9561FB")};
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const NoDecorationButton = styled(Button)`
  border: none;
  background: none;
  padding: 0px;
  margin: 0px;
  &:hover {
    background: "none";
  }
  &:active {
    background: "none";
    border: "none";
  }
  &:focus {
    background: "none";
  }
`;
const DatePickerWrapperStyles = createGlobalStyle`
    .date_picker.full-width {
        width: 100%;
    }
`;

export default GlobalStyle;
