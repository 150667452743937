import {
  Heading,
  VStack,
  Table,
  Thead,
  Tbody,
  Select,
  Tr,
  Th,
  Td,
  HStack,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";
import acer from "../../../assets/products/pc/61yrDGIAcML._AC._SR360,460.jpg";

export default function TableComp({ name }) {
  //Function
  function Selector() {
    return (
      <VStack alignItems={"flex-start"}>
        <Select placeholder="PC Select">
          <option value="option1">Option 1</option>
          <option value="option2">Option 2</option>
          <option value="option3">Option 3</option>
        </Select>
        <HStack alignItems={"flex-start"}>
          <Image
            boxSize="100px"
            objectFit="contain"
            src={acer}
            alt="samsung-galaxy-a21s"
          />
          <Text>Samsung Galaxy F62</Text>
        </HStack>
        {/* <Text>$189.99</Text> */}
      </VStack>
    );
  }

  //Jsx
  return (
    <Table variant="simple">
      <Thead>
        <Tr bg={"#E9ECFF"}>
          <Th>{name}</Th>
          <Th></Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>Compare</Td>
          <Td>
            <Selector />
          </Td>
          <Td>
            <Selector />
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
}

const Data = [
  {
    name: "Launch Date",
    desc: "December 9, 2021",
  },
  {
    name: "Processor Chipset",
    desc: "Unisoc SC9863A",
  },
  {
    name: "RAM",
    desc: "2 GB",
  },
  {
    name: "Internal Memory",
    desc: "32 GB",
  },
  {
    name: "Screen Size",
    desc: "6.5 inches (16.51 cms)",
  },
  {
    name: "Battery Capacity",
    desc: "5000 mAh",
  },
];
