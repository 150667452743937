import { Center, HStack, Img, Stack, Text, Wrap } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { filteredProducts } from "../Data";
import acer from "../../../assets/products/pc/61yrDGIAcML._AC._SR360,460.jpg";

const FilteredProducts = () => {
  return (
    <Wrap
      justify={{ base: "center", md: "start" }}
      spacing={{ base: "12px", md: "20px" }}
    >
      {filteredProducts.map((product) => (
        <Link to={"/computer/product_detail/5"} key={product?.id}>
          <Stack align={"center"}>
            <Stack
              w={{
                base: "160px",
                sm: "190px",
                md: "190px",
                lg: "180px",
                xl: "200px",
                "2xl": "200px",
              }}
              h={{
                base: "205px",
                sm: "310px",
                md: "310px",
                lg: "325px",
                xl: "325px",
                "2xl": "325px",
              }}
              //   pl="10%"
              bgImage={`url(${acer})`}
              bgPosition="center"
              maxH="313px"
              bgRepeat="no-repeat"
            >
              <Stack
                h="100%"
                w="100%"
                //   align={"center"}
                mb={3}
                justify={"space-between"}
                mx={"5%"}
              >
                <Text
                  display={product?.earlierPrice ? "initial" : "none"}
                  fontSize={"70%"}
                  m={"10px 0px"}
                  py={1}
                  borderRadius={"10px"}
                  bgColor="#2FC61F"
                  maxW={"100px"}
                  minW={"70px"}
                  align={"center"}
                  opacity={"0.85"}
                  color="#FFF"
                >
                  {(
                    ((product?.earlierPrice - product?.price) /
                      product?.earlierPrice) *
                    100
                  ).toFixed()}
                  % off
                </Text>
              </Stack>
            </Stack>
            <Center fontWeight="bold" lineHeight={"18px"} align="center">
              {product?.name}
            </Center>
            {/* <Img src={fourStar} /> */}
            <HStack spacing={"7px"}>
              {/* <Text
                display={product?.earlierPrice ? "flex" : "none"}
                color="#EA4335 "
                as="s"
              >
                Br.{product?.earlierPrice}
              </Text>
              <Text color="#4962f2">Br.{product?.price} </Text> */}
            </HStack>
          </Stack>
        </Link>
      ))}
    </Wrap>
  );
};

export default FilteredProducts;
