import { Heading, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import BreadcrumbComponent from "../../shared/components/BreadcrumbComponent";
import TopSelectionTab from "../../shared/components/TopSelectionTab";
import Preview from "./components/Preview";
import { AiFillStar, AiFillDislike, AiFillLike } from "react-icons/ai";
import styled from "styled-components";
import SpecTable from "./components/SpecTable";

export default function CompProductDetail() {
  //Function
  function Rating() {
    return (
      <VStack py={4} px={{ base: 0, md: 4, lg: 8 }} boxShadow={"lg"}>
        <HStack justifyContent={"space-around"} w={"100%"} pt={4} pb={2}>
          <Heading size={"sm"}>Acer Aspire 5 A515-46-R3UB</Heading>
          <Divider />
          <Heading size={"sm"}>8.5 Expert Score</Heading>
          <Divider />
          <HStack>
            <AiFillStar color={"#F2AB55"} />
            <Heading size={"sm"}>4.7 15 Rating</Heading>
          </HStack>
        </HStack>
        <DividerVertical />
        <Text pt={2}>
          Powerful Productivity: AMD Ryzen 3 3350U delivers desktop-class
          performance and amazing battery life in a slim notebook. With
          Precision Boost, get up to 3.5GHz for your high-demand applications
          Maximized Visuals: See even more on the stunning 15.6" Full HD display
          with 82.58% screen-to-body, 16:9 aspect ratio and narrow bezels
          Backlit Keyboard and Fingerprint Reader: Biometric fingerprint reader
          and Windows Hello sign-in options help keep your Acer PC secure
          Internal Specifications: 4GB DDR4 on-board memory (1 slot available);
          128GB NVMe solid-state drive storage (1 hard drive bay available) to
          store your files and media. Acer's Purified.Voice technology, features
          enhanced digital signal processing to cancel out background noise,
          improve speech accuracy and far-field pickup, which not only makes
          calls clearer, but makes talking to Alexa easier than before. With
          Alexa Show Mode, you can use your PC as a full-screen interactive
          experience that features rich visuals and lets you talk to Alexa from
          across the room.
        </Text>
      </VStack>
    );
  }

  function Pro() {
    return (
      <VStack
        py={4}
        px={{ base: 0, md: 4, lg: 8 }}
        boxShadow={"lg"}
        alignItems={"flex-start"}
      >
        <Heading size={"sm"}>Acer Aspire 5 A515-46-R3UB</Heading>
        <DividerVertical />
        <HStack
          justifyContent={"flex-start"}
          spacing={"25%"}
          w={"100%"}
          px={{ base: 0, md: 8, lg: 16 }}
        >
          <VStack alignItems={"flex-start"}>
            <HStack color={"#4962F2"}>
              <AiFillDislike />
              <Heading size={"sm"}>Good Stuff</Heading>
            </HStack>
            <Heading size={"sm"}>Acer Aspire 5 A515-46-R3UB</Heading>
            <Heading size={"sm"}>8A515-46-R3UB</Heading>
            <Heading size={"sm"}>Screen Size: 15.6 Inches</Heading>
            <Heading size={"sm"}>6.5 inches PLS TFT LCD Display</Heading>
          </VStack>
          <VStack alignItems={"flex-start"}>
            <HStack color={"#F25555"}>
              <AiFillLike />
              <Heading size={"sm"}>Bad Staff</Heading>
            </HStack>
            <Heading size={"sm"}>4 GB RAM</Heading>
            <Heading size={"sm"}>1000 GB Storage</Heading>
            <Heading size={"sm"}>5000 mAh Battery</Heading>
            <Heading size={"sm"}>Ryzen 3</Heading>
          </VStack>
        </HStack>
      </VStack>
    );
  }

  function Detail() {
    return (
      <VStack
        py={4}
        px={{ base: 0, sm: 8 }}
        boxShadow={"lg"}
        alignItems={"flex-start"}
      >
        <Heading size={"sm"}>Acer Aspire 5 A515-46-R3UB</Heading>
        <DividerVertical />
        <VStack alignItems={"flex-start"}>
          <Heading size={"sm"}>Display, Deign, Security</Heading>
          <Text>
            Secure Operating System: Windows 11 Home in S mode comes with
            advanced security features built right in, like protections against
            phishing and malicious software so you don't have to think twice
            when navigating to a new webpage or downloading an app
          </Text>
        </VStack>
        <VStack alignItems={"flex-start"}>
          <Heading size={"sm"}>Display, Deign, Security</Heading>
          <Text>
            Products with electrical plugs are designed for use in the US.
            Outlets and voltage differ internationally and this product may
            require an adapter or converter for use in your destination. Please
            check compatibility before purchasing.
          </Text>
        </VStack>
      </VStack>
    );
  }

  //Jsx
  return (
    <>
      <TopSelectionTab />
      <Stack
        py={{ base: 4, md: 6 }}
        px={{ base: 4, sm: 6, md: 8, lg: 10 }}
        spacing={6}
      >
        {/* <BreadcrumbComponent /> */}
        <Preview />
        <Rating />
        <Pro />
        <Detail />
        <SpecTable />
      </Stack>
    </>
  );
}

const Divider = styled.div`
  height: 25px;
  width: 1px;
  background-color: black;
`;

const DividerVertical = styled.div`
  height: 1px;
  width: 100%;
  background-color: black;
`;
