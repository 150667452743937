import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { ANavLinks, NavItem, NavLinks, NavLinksBox } from "./NavBar.elements";
import { useMediaQuery } from "@chakra-ui/media-query";
import {
  Box,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { useAuth } from "../../shared/hooks/auth-hook";
import { useLang } from "../../shared/hooks/lang-hook";

function StyledNavLinks() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isSmallerThan992] = useMediaQuery("(max-width: 992px)");
  const [isGreaterThan1200] = useMediaQuery("(min-width: 1200px)");
  const { changeLang, isOro, isAmh } = useLang();

  const adminMode = location?.pathname.startsWith("/administration");

  const [button, setButton] = useState(true);

  const { token, logout } = useAuth();

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  console.log({ scrollPosition });

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const blogCatData = useQuery(
    "blogCategoryLists",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/blog-categories`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {},
    }
  );
  console.log({ blogCatData });

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };
  const onLogout = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  const about =
    location?.pathname.toLowerCase() === "/about" ||
    location?.pathname.toLowerCase() === "/about/";

  return (
    <Stack direction={{ base: "column", lg: "row" }} spacing={"2px"}>
      {token && (
        <NavItem>
          <NavLinks
            style={{
              color:
                about && scrollPosition < 100 && !isSmallerThan992 && "#FFF",
            }}
            to="/"
            cursor="pointer"
          >
            {isOro ? "Mana" : isAmh ? "ቤት" : "Home"}
          </NavLinks>
        </NavItem>
      )}{" "}
      {token && (
        <NavItem>
          <NavLinks to="/blogs" cursor="pointer">
            {isOro ? "Barreeffama" : isAmh ? "ዜና" : "Blogs"}
          </NavLinks>
        </NavItem>
      )}{" "}
      <NavItem>
        <NavLinksBox
          style={{
            color: about && scrollPosition < 100 && !isSmallerThan992 && "#FFF",
          }}
          onClick={() => changeLang(false, false)}
          bgRounded={!isAmh && !isOro}
          cursor="pointer"
        >
          {"English"}
        </NavLinksBox>
      </NavItem>{" "}
      <NavItem>
        <NavLinksBox
          style={{
            color: about && scrollPosition < 100 && !isSmallerThan992 && "#FFF",
          }}
          onClick={() => changeLang(true, false)}
          bgRounded={isAmh}
          cursor="pointer"
        >
          Amharic{" "}
        </NavLinksBox>
      </NavItem>{" "}
      <NavItem>
        <NavLinksBox
          style={{
            color: about && scrollPosition < 100 && !isSmallerThan992 && "#FFF",
          }}
          onClick={() => changeLang(false, true)}
          bgRounded={isOro}
          cursor="pointer"
        >
          Oromifa{" "}
        </NavLinksBox>
      </NavItem>
      {token && (
        <NavItem adminMode={adminMode}>
          <NavLinksBox
            adminMode={adminMode}
            style={{
              color:
                about && scrollPosition < 100 && !isSmallerThan992 && "#FFF",
            }}
            onClick={onLogout}
          >
            {isOro ? "Sochii Ala" : isAmh ? "ውጣ" : "Log out"}
          </NavLinksBox>
        </NavItem>
      )}
      {/* {token && (
        <NavItemBtn>
          <NavBtnLink>
            {button ? (
              <StyledButton logout small onClick={auth.subscriberLogout}>
                LOGOUT
              </StyledButton>
            ) : (
              <StyledButton onClick={auth.subscriberLogout} big fontBig primary>
                LOGOUT
              </StyledButton>
            )}
          </NavBtnLink>
        </NavItemBtn>
      )} */}
    </Stack>
  );
}

export { StyledNavLinks };
