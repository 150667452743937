import blogImage from "../../assets/blogs/Image 1.png";

export const bigBlogTwo = {
  id: 1,
  title: "Salt caravans of the Danakil depression",
  description:
    "The thick warm air, the hazy sky and the rugged empty mountains that gradually give way to the immensity of a white, shimmering salt desert all leave the traveller in awe of this cruel yet fascinating landscape. Overlapping the Afar region of northeastern Ethiopia, Eritrea and Djibouti, this is the lowest point in Africa and one of the hottest places on Earth.",
  // image: denakil,
};
export const filteredProducts = [
  {
    id: 1,
    name: "Galaxy Tab S7",
    img: "galexyTabS7",
    online: true,
    store: false,
    rating: 4,
    price: 17600.0,
    // earlierPrice: 920.0,
  },
  {
    id: 2,
    name: "PROSPOROUS SOFA",
    img: "newprosporousSofa",
    online: true,
    store: true,
    rating: 4,
    price: 26000,
  },
  {
    id: 3,
    name: "Oumy Tray",
    img: "oumyTray",
    online: true,
    store: false,
    rating: 4,
    price: 12600.0,
    // earlierPrice: 930.0,
  },
  {
    id: 4,
    name: "PROSPOROUS SOFA",
    img: "prosporousSofa",
    online: true,
    store: true,
    rating: 4,
    price: 26000,
  },
  {
    id: 5,
    name: " Door-in-Door® Refrigerator",
    img: "refrigerator",
    online: false,
    store: true,
    rating: 4,
    price: 20000,
    // earlierPrice: 52000,
  },
  {
    id: 6,
    name: "BESPOKE  Microwave ",
    img: "beSpokeMicorwave",
    online: false,
    store: true,
    rating: 4,
    price: 10000,
    // earlierPrice: 52000,
  },
  {
    id: 7,
    name: "4K Ultra HD TV ",
    img: "fourKUltraHDTv",
    online: true,
    store: true,
    rating: 4,
    price: 8000,
  },
  {
    id: 8,
    name: "4K Ultra HD TV",
    img: "ultraHDTV",
    online: true,
    store: true,
    rating: 4,
    price: 13000,
  },
  {
    id: 9,
    name: "BH Cosmetics",
    img: "cosmo",
    online: true,
    store: true,
    logo: "cosmoLogo",
    price: 15000,
  },
  {
    id: 10,
    name: "Schutz",
    img: "schutz",
    online: false,
    store: true,
    logo: "schutzLogo",
    price: 11000,
  },
  {
    id: 11,
    name: "Expedition Subsahara",
    img: "expedition",
    online: true,
    store: true,
    logo: "expeditionLogo",
    price: 36000,
  },
  {
    id: 12,
    name: "Liten Spire",
    img: "liten",
    online: false,
    store: true,
    logo: "litenLogo",
    price: 9000,
  },
];

export const filterData = [
  {
    name: "Product Brand",
    render: false,
    moreIcon: "",
    more: [
      {
        name: "Iphone",
      },
      {
        name: "Samsung",
      },
      {
        name: "Lg",
      },
      {
        name: "Xiamo",
      },
      {
        name: "Micromax",
      },
      {
        name: "Spice",
      },
    ],
  },
];

export const featuresData = [
  {
    name: "Features",
    render: false,
    moreIcon: "",
    more: [
      {
        name: "Iphone",
      },
      {
        name: "Samsung",
      },
      {
        name: "Lg",
      },
      {
        name: "Xiamo",
      },
      {
        name: "Micromax",
      },
      {
        name: "Spice",
      },
    ],
  },
];

export const pricesData = [
  {
    name: "",
    render: false,
    moreIcon: "",
    more: [
      {
        name: "Below 10000br",
      },
      {
        name: "10000br - 15000 br",
      },
      {
        name: "10000br 15000br",
      },
      {
        name: "10000br 15000br",
      },
      {
        name: "100000br 15000br",
      },
      {
        name: "10000br - 15000br",
      },
    ],
  },
];

export const blogsData = [
  {
    img: blogImage,
    title: "Must Checkout 13 Free Coolest App of 2021",
    time: "15 mins reading",
  },
  {
    img: blogImage,
    title: "Must Checkout 13 Free Coolest App of 2021",
    time: "15 mins reading",
  },
  {
    img: blogImage,
    title: "Must Checkout 13 Free Coolest App of 2021",
    time: "15 mins reading",
  },
];
