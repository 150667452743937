import {
  Heading,
  VStack,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from "@chakra-ui/react";
import React from "react";

export default function TableComp({ name }) {
  return (
    <Table variant="simple">
      <Thead>
        <Tr bg={"#E9ECFF"}>
          <Th>{name}</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {Data.map((data, index) => {
          return (
            <Tr key={index}>
              <Td>{data.name}</Td>
              <Td>{data.desc}</Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}

const Data = [
  {
    name: "Launch Date",
    desc: "December 9, 2021",
  },
  {
    name: "Processor Chipset",
    desc: "Unisoc SC9863A",
  },
  {
    name: "RAM",
    desc: "2 GB",
  },
  {
    name: "Internal Memory",
    desc: "32 GB",
  },
  {
    name: "Screen Size",
    desc: "6.5 inches (16.51 cms)",
  },
  {
    name: "Battery Capacity",
    desc: "5000 mAh",
  },
];
