import {
  Box,
  HStack,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";

const PriceRange = () => {
  const [priceStart, setPriceStart] = useState(0);
  const [priceEnd, setPriceEnd] = useState(10000);
  return (
    <Stack>
      <Text fontWeight={"bold"}>Price</Text>
      <RangeSlider
        onChangeEnd={(val) => {
          setPriceEnd(val[1]);
        }} // onChangeStart={(val) => console.log({ val })}
        onChangeStart={(val) => {
          setPriceStart(val[0]);
        }} // onChangeStart={(val) => console.log({ val })}
        aria-label={["min", "max"]}
        defaultValue={[0, 100000]}
        min={0}
        max={10000}
      >
        <RangeSliderTrack bg="#BEBEBE">
          <RangeSliderFilledTrack bg="#4962F2" />
        </RangeSliderTrack>
        <RangeSliderThumb boxSize={5} index={0}>
          <Box color="#4962F2" />
        </RangeSliderThumb>
        <RangeSliderThumb boxSize={5} index={1}>
          <Box color="#4962F2" />
        </RangeSliderThumb>
      </RangeSlider>
      <Stack direction={"row"} justify={"center"}>
        <HStack flexGrow={"1"}>
          <Text>br</Text>
          <Text border={"1px solid #4962F2"} borderRadius="5px" px={1}>
            {priceStart}
          </Text>
        </HStack>
        <HStack>
          <Text>br</Text>
          <Text border={"1px solid #4962F2"} px={1}>
            {priceEnd}
          </Text>
        </HStack>
      </Stack>
    </Stack>
  );
};

export default PriceRange;
