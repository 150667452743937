import {
  Heading,
  VStack,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from "@chakra-ui/react";
import React from "react";

export default function SpecTable() {
  //Function
  function TableComp({ name }) {
    return (
      <Table variant="simple">
        <Thead>
          <Tr bg={"#E9ECFF"}>
            <Th>{name}</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {Data.map((data, index) => {
            return (
              <Tr key={index}>
                <Td>{data.name}</Td>
                <Td>{data.desc}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    );
  }

  //Jsx
  return (
    <VStack py={4} px={8} boxShadow={"lg"} alignItems={"flex-start"}>
      <Heading size={"sm"}>
        Acer Aspire 5 A515-46-R3UB Core: Specifications
      </Heading>
      <TableComp name="Summery" />
      <TableComp name="Performance" />
      <TableComp name="Design" />
      <TableComp name="Display" />
    </VStack>
  );
}

const Data = [
  {
    name: "Launch Date",
    desc: "December 9, 2021",
  },
  {
    name: "Processor Chipset",
    desc: "Unisoc SC9863A",
  },
  {
    name: "RAM",
    desc: "2 GB",
  },
  {
    name: "Internal Memory",
    desc: "32 GB",
  },
  {
    name: "Screen Size",
    desc: "6.5 inches (16.51 cms)",
  },
  {
    name: "Battery Capacity",
    desc: "5000 mAh",
  },
];
