import React, { useContext, useEffect, useState } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Img,
  Box,
  HStack,
  Text,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Input,
  Heading,
  Stack,
} from "@chakra-ui/react";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavMenu,
  MobileIcon,
} from "./NavBar.elements";
import { StyledNavLinks } from "./StyledNavLinks";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@chakra-ui/media-query";
import { SearchIcon } from "@chakra-ui/icons";
import { useLang } from "../../shared/hooks/lang-hook";
import { useAuth } from "../../shared/hooks/auth-hook";
import { Logo } from "./Logo";

function MainNavigation({ click, setClick }) {
  //on handleKeyDown
  const { token } = useAuth();

  const keyPress = (event) => {
    if (event?.key === "Enter") {
      navigate("/all-products", { state: "" });
      // setSearchValue("");
    }
  };
  const handleClick = (e) => {
    setClick(!click);
  };
  const [isSmallerThan350] = useMediaQuery("(max-width: 350px)");

  const navigate = useNavigate();
  const { changeLang, isOro, isAmh } = useLang();

  const [isSmallerThan992] = useMediaQuery("(max-width: 992px)");

  const location = useLocation();

  const adminMode = location?.pathname.startsWith("/administration");
  const btnRef = React.useRef();

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  console.log({ scrollPosition });

  return (
    <Nav
      transparentBg={
        (location?.pathname.toLowerCase() === "/about" ||
          location?.pathname.toLowerCase() === "/about/") &&
        scrollPosition < 100 &&
        !isSmallerThan992
          ? true
          : false
      }
      adminMode={adminMode}
    >
      <NavbarContainer>
        <NavLogo to="/">
          <HStack spacing={{ base: "-2px", sm: "2px" }}>
            <Logo />
          </HStack>
        </NavLogo>

        <Stack>
          <Box />
          <HStack spacing={"-20px"}>
            <Box />
            <MobileIcon onClick={handleClick} ref={btnRef}>
              {click ? (
                <Img
                  w="30px"
                  src="https://img.icons8.com/material-outlined/24/000000/delete-sign.png"
                />
              ) : (
                <Img
                  w="30px"
                  src="https://img.icons8.com/material-outlined/24/000000/menu.png"
                />
              )}
            </MobileIcon>
          </HStack>
        </Stack>
        {isSmallerThan992 ? (
          <Drawer
            isOpen={click}
            placement="left"
            onClose={handleClick}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <Box w="100%" mt="10px">
                <Link to="/">
                  <HStack
                    justifySelf={"center"}
                    alignSelf={"center"}
                    spacing={"2px"}
                  >
                    <Logo />
                    <Heading color="#56A59A" fontSize={"2xl"}></Heading>
                  </HStack>
                </Link>
              </Box>
              <StyledNavLinks />
            </DrawerContent>
          </Drawer>
        ) : (
          <NavMenu onClick={handleClick} click={click}>
            <StyledNavLinks />
          </NavMenu>
        )}
      </NavbarContainer>
    </Nav>
  );
}

export default MainNavigation;
