import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ComputerPage from "../pages/computer/ComputerPage";
import CompCompare from "../pages/computerCompare/CompCompare";
import CompProductDetail from "../pages/computerProductDetail/CompProductDetail";
import BlogSection from "../pages/home/components/BlogSection";
const HomePage = React.lazy(() => import("../pages/home/HomePage"));
const Blog = React.lazy(() => import("../pages/Blog/Blog"));
const Compare = React.lazy(() => import("../pages/Compare/Compare"));
const ProductDetail = React.lazy(() =>
  import("../pages/ProductDetail/ProductDetail")
);

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/phone" element={<HomePage />} />
      <Route path="/computer" element={<ComputerPage />} />
      <Route path="/phone/compare" element={<Compare />} />{" "}
      <Route path="/computer/compare" element={<CompCompare />} />
      <Route
        path="/computer/product_detail/:id"
        element={<CompProductDetail />}
      />
      <Route path="/phone/product_detail/:id" element={<ProductDetail />} />
      <Route path="/blogs" element={<BlogSection />} />
      <Route path="/blogs/1" element={<Blog />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AuthRoutes;
