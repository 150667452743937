import {
  Button,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";
// import logo from "../../../assets/logo-colour.png";
// import acer2 from "../../../assets/products/pc/acerpc.jpg";
// import acer2 from "../../../assets/products/pc/acerpc.jpg";
import acer2 from "../../../assets/products/pc/acerpc.jpg";
import { useNavigate } from "react-router-dom";

export default function Preview() {
  const navigate = useNavigate();

  //Function
  function ImagePreview() {
    return (
      <Stack
        direction={{ base: "column-reverse", lg: "row" }}
        spacing={4}
        w={{ base: "100%", lg: "45%" }}
      >
        <Stack direction={{ base: "row", lg: "column" }}>
          <Image
            boxSize="100px"
            objectFit="contain"
            src={acer2}
            alt="Dan Abramov"
          />
          <Image
            boxSize="100px"
            objectFit="contain"
            src={acer2}
            alt="Dan Abramov"
          />
          <Image
            boxSize="100px"
            objectFit="contain"
            src={acer2}
            alt="Dan Abramov"
          />
        </Stack>
        <Image
          maxW={"470px"}
          width="100%"
          objectFit="cover"
          src={acer2}
          alt="Dan Abramov"
        />
      </Stack>
    );
  }
  function Description() {
    return (
      <VStack
        alignItems={"flex-start"}
        px={{ base: 2, md: 4, lg: 4 }}
        w={{ base: "100%", lg: "55%" }}
        spacing={8}
      >
        <HStack justifyContent={"space-between"} w={"100%"} pr={16}>
          {/* <div className="flex flex-row gap-2">
            <Heading size={"sm"}>Best price:</Heading>
            <Heading size={"sm"} color={"blue.500"}>
              19,500 br
            </Heading>
          </div> */}
          <Button
            rounded={"3xl"}
            onClick={() => navigate("/computer/compare")}
            variant={"outline"}
            colorScheme={"blue"}
          >
            Compare
          </Button>
        </HStack>
        <div className="flex flex-col gap-4">
          <Text>Variants</Text>
          <HStack>
            <Tags color={"blue"}>6GB + 64GB</Tags>
            <Tags color={"red"}>68GB + 128GB</Tags>
            <Tags color={"red"}>6GB + 64GB</Tags>
          </HStack>
        </div>
        {/* <HStack
          justifyContent={"space-between"}
          w={"100%"}
          boxShadow={"lg"}
          px={8}
        >
          <HStack>
            <Image
              boxSize="100px"
              objectFit="contain"
              src={logo}
              alt="Dan Abramov"
            />
            <Heading color={"blue"} size={"sm"}>
              25,500 br
            </Heading>
          </HStack>
          <Button rounded={"3xl"} variant={"solid"} colorScheme={"blue"}>
            Go to store
          </Button>
        </HStack> */}
        <div className="grid grid-cols-2 pt-8 gap-4">
          <Text>Acer Aspire 5 A515-46-R3UB</Text>
          <Text>8A515-46-R3UB</Text>
          <Text>Screen Size: 15.6 Inches</Text>
          <Text>6.5 inches PLS TFT LCD Display</Text>
          <Text>4 GB RAM</Text>
          <Text>1000 GB Storage</Text>
          <Text>5000 mAh Battery</Text>
          <Text>Ryzen 3</Text>
        </div>
      </VStack>
    );
  }

  //Jsx
  return (
    <Stack direction={{ base: "column", lg: "row" }}>
      <ImagePreview />
      <Description />
    </Stack>
  );
}

const Tags = styled.div`
  border-width: 1px;
  border-color: red;
  color: ${(props) => props.color};
  padding: 1px 5px;
  font-size: 12px;
`;
