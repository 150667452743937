import { Button, Heading, HStack } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function TopSelectionTab() {
  return (
    <HStack bg={"lavender"} spacing={6} justifyContent={"center"} py={5}>
      <Link to="/computer">
        <Button size={"md"} color={"black"} variant={"link"} bg={"transparent"}>
          Computer
        </Button>
      </Link>
      <Divider />
      <Link to="/phone">
        <Button size={"md"} color={"black"} variant={"link"} bg={"transparent"}>
          Mobile Phones
        </Button>
      </Link>
    </HStack>
  );
}

const Divider = styled.div`
  height: 25px;
  width: 1px;
  background-color: black;
`;
