import { Heading, VStack } from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";
import BreadcrumbComponent from "../../shared/components/BreadcrumbComponent";
import TopSelectionTab from "../../shared/components/TopSelectionTab";
import TableComp from "./components/TableComp";
import SelectTableComp from "./components/SelectTableComp";

export default function CompCompare() {
  return (
    <>
      <TopSelectionTab />
      <VStack py={4} px={8} alignItems={"flex-start"}>
        <BreadcrumbComponent />
        <div className="flex flex-col w-full shadow-lg p-8 gap-5 rounded-md">
          <Heading size={"sm"}>Acer Aspire 5 A515-46-R3UB</Heading>
          <DividerVertical />
          <div className="flex flex-col shadow-md mx-8 gap-5 rounded-md">
            <SelectTableComp name="Compare" />
            <TableComp name="Summery" />
            <TableComp name="Performance" />
            <TableComp name="Design" />
            <TableComp name="Display" />
          </div>
        </div>
      </VStack>
    </>
  );
}

const DividerVertical = styled.div`
  height: 0.5px;
  width: 100%;
  background-color: grey;
`;
