import {
  Box,
  Checkbox,
  HStack,
  Img,
  Stack,
  Text,
  Wrap,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
// import longAd from "../../../assets/ads/longAd.svg";

const SearchSection = ({ filterData }) => {
  return (
    <Stack spacing={"50%"}>
      <Stack>
        {filterData.map((filter, index) => (
          <Stack align="end" justify={"center"} key={index}>
            <HStack>
              <Text flexGrow={1} fontWeight={"semibold"} fontSize="17px">
                {filter?.name}
              </Text>
              <Img cursor={"pointer"} w="10px" src={filter?.moreIcon} />
            </HStack>
            <Wrap justify={"end"} align="center">
              {filter.more.map((fil, index) => {
                console.log({ fil });

                return (
                  <Stack direction={"row"} justify={"center"} key={index}>
                    <Box flexGrow={1} />
                    <Img
                      display={filter?.render ? "flex" : "none"}
                      alignItems="end"
                      src={fil?.img}
                    />
                    <Checkbox
                      colorScheme="blue"
                      defaultIsChecked={
                        index === 0 || index === 4 || index === 2 ? true : false
                      }
                      display={filter?.render ? "none" : "flex"}
                    />

                    <Text textAlign={"end"}>{fil?.name}</Text>
                  </Stack>
                );
              })}
            </Wrap>
          </Stack>
        ))}
      </Stack>{" "}
      {/* <Img src={longAd} display={{ base: "none", md: "flex" }} /> */}
    </Stack>
  );
};

export default SearchSection;
